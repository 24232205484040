import React from "react"
import Img from "gatsby-image"

import TextSection from "../../objects/TextSection"

import { Section, 
         Container, 
         Column,
         Title,
         Subtitle,
         DestakPhoto } from "./styles"

const CorporateSection = ({ className, imgFluid }) => {
   return (
      <Section className={className}>
         <Container>
            <Column className="column1">
               <Title titlepage="Vendas Corporativas" titlesection="" />
               <Subtitle subtitle="Para aquitetos, designers e outros" />
            </Column>
            <Column className="column2">
               <TextSection>
                  Oferecemos preços de comércio para projetos para arquitetos, designers de interiores e outros. Fornecemos os melhores produtos de móveis e decoração disponíveis das principais marcas, provenientes de uma extensa biblioteca de produtos (além da mostrada neste site). Fornecemos cotações rápidas e competitivas, seja para peças únicas ou pacotes para projetos maiores.
               </TextSection>
               <TextSection>
                  Entrega e instalação completa estão disponíveis e nossos instaladores especializados são totalmente treinados e equipados.
               </TextSection>
               <TextSection>
                  Oferecemos os melhores preços disponíveis para projetos.           
               </TextSection>
               <DestakPhoto>
                  <Img fluid={imgFluid} />
               </DestakPhoto>
               <TextSection>
                  Você pode registrar os detalhes da sua empresa abaixo para facilitar ao entrar em contato conosco para obter cotações. Em seguida, basta enviar um e-mail ou ligue para nós com detalhes dos produtos necessários. Enviaremos sua cotação por e-mail.
               </TextSection>
            </Column>
         </Container>
      </Section>
   )
}

export default CorporateSection