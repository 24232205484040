import React from "react"
import styled from "styled-components";

const Subtitle = styled.h3`
   font-family: ${props => props.theme.font.primary};
   font-size: ${props => props.theme.size.medium};
   line-height: 2.4rem;
   font-weight: 600;
   color: ${props => props.theme.color.primaryLight2};
`

const SubtitleSection = ({ subtitle, className }) => {
   return (
      <Subtitle className={className}>{subtitle}</Subtitle>
   )
}

export default SubtitleSection