import React, { useState } from "react"
import { navigate } from "gatsby-link"
import { useForm } from "react-hook-form"

import { 
   WrapForm,
   InputItem,
   FieldItem,
   MsgErrorField,
   // TextArea,
   // TextAreaItem,
   InfoAdd,
   ActionArea,
   Button } from "./styles";

function encode(data) {
   return Object
      .keys(data)
      .map((key) => 
         encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join("&")
}
   
// FORM CONTACT NETLIFY FORMS + ZAPIER + SENDGRID + REACT HOOK FORM
const RegistryForm = () => {

   const [formValues, setformValues] = useState({})

   const { register, handleSubmit, errors, reset } = useForm({
      mode: "onChange"
   })

   const handleChange = e => {
      setformValues({ ...formValues, [e.target.name]: e.target.value })
   }

   const onSubmit = (data, e) => {
      e.preventDefault()
      fetch("/", {
         method: "POST",
         headers: { "Content-Type": "application/x-www-form-urlencoded" },
         body: encode({
            "form-name": "contact",
            ...formValues,
         }),
      })
         .then(() => {
            reset()
            navigate("/Success/")
         })
         .catch (error => { 
            alert(error) 
         }) 
   }

   return (
      <WrapForm 
         name="contact"
         method="post"
         action="/Success/"
         data-netlify="true"
         data-netlify-honeypot="bot-field"
         onSubmit={handleSubmit(onSubmit)}
         
      >
         <input type="hidden" name="form-name" value="contact" />
         <InputItem  className="firstname">
            <FieldItem 
               id="firstname" 
               name="firstname"
               type="text" 
               placeholder="Nome"
               onChange={handleChange}
               ref={register({
                  required: "* Preechimento obrigatório!",
                  minLength: {
                     value: 2, 
                     message: "* Nome muito curto!"
                  },
                  maxLength: {
                     value: 50, 
                     message: "* Nome muito longo!"
                  }
               })}
            />
            {errors.firstname && 
               <MsgErrorField>{errors.firstname.message}</MsgErrorField>
            }
         </InputItem>
         <InputItem className="lastname">
            <FieldItem 
               id="lastname" 
               name="lastname"
               type="text" 
               placeholder="Sobrenome"
               onChange={handleChange}
               ref={register({
                  required: "* Preechimento obrigatório!",
                  minLength: {
                     value: 2, 
                     message: "* Sobrenome muito curto!"
                  },
                  maxLength: {
                     value: 50, 
                     message: "* Sobrenome muito longo!"
                  }
               })}
            />
            {errors.lastname && 
               <MsgErrorField>{errors.lastname.message}</MsgErrorField>
            }
         </InputItem> 
         <InputItem className="company">
            <FieldItem 
               id="company" 
               name="company"
               type="text" 
               placeholder="Empresa"
               onChange={handleChange}
               ref={register({
                  required: "* Preechimento obrigatório!",
                  minLength: {
                     value: 2, 
                     message: "* Nome muito curto!"
                  },
                  maxLength: {
                     value: 50, 
                     message: "* Nome muito longo!"
                  }
               })}
            />
            {errors.company && 
               <MsgErrorField>{errors.company.message}</MsgErrorField>
            }
         </InputItem> 
         <InputItem className="email">
            <FieldItem
               id="email"
               name="email" 
               type="email" 
               placeholder="E-mail"
               onChange={handleChange}
               ref={register({
                  required: "* Preechimento obrigatório!",
                  pattern: {
                     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                     message: "* Digite um e-mail válido!",
                  }
               })}
            />
            {errors.email && 
               <MsgErrorField>{errors.email.message}</MsgErrorField>
            }
         </InputItem>
         <InputItem className="phone">
            <FieldItem
               id="phone"
               name="phone"  
               type="text" 
               placeholder="Telefone"
               onChange={handleChange}
               ref={register({
                  required: "* Preechimento obrigatório!",
                  pattern: {
                     value: /^1\d\d(\d\d)?$|^0800 ?\d{3} ?\d{4}$|^(\(0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d\) ?|0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d[ .-]?)?(9|9[ .-])?[2-9]\d{3}[ .-]?\d{4}$/gm,
                     message: "* Digite um telefone válido!",
                  }
               })}
            />
            {errors.phone && 
               <MsgErrorField>{errors.phone.message}</MsgErrorField>
            }
         </InputItem>
         {/* <TextAreaItem>
            <TextArea
               id="message" 
               name="message" 
               rows="3" 
               placeholder="Deixe sua mensagem"
               onChange={handleChange}
               ref={register({ required: true })}
            />
            {errors.message && 
               <MsgErrorField>* Preechimento obrigatório!</MsgErrorField>
            }
         </TextAreaItem> */}

         <InfoAdd 
            className="info">Informações adicionais:
         </InfoAdd>
         <InputItem className="business">
            <FieldItem 
               id="business" 
               name="business"
               type="text" 
               placeholder="Tipo(s) de negócio(s)"
               onChange={handleChange}
               ref={register({
                  required: "* Preechimento obrigatório!",
                  minLength: {
                     value: 2, 
                     message: "* Nome muito curto!"
                  },
                  maxLength: {
                     value: 50, 
                     message: "* Nome muito longo!"
                  }
               })}
            />
            {errors.business && 
               <MsgErrorField>{errors.business.message}</MsgErrorField>
            }
         </InputItem> 

         <InputItem className="urlsite">
            <FieldItem
               id="urlsite"
               name="urlsite" 
               type="text" 
               placeholder="Site da empresa"
               onChange={handleChange}
               // ref={register({
               //    required: "* Preechimento obrigatório!",
               //    pattern: {
               //       value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
               //       message: "* Digite um e-mail válido!",
               //    }
               // })}
            />
            {/* {errors.urlsite && 
               <MsgErrorField>{errors.urlsite.message}</MsgErrorField>
            } */}
         </InputItem>

         <ActionArea className="btnsubmit">
            <Button type="submit">Enviar Mensagem</Button>
            {/* <Button type="reset">Limpar</Button> */}
         </ActionArea>
      </WrapForm>   
   )
}

export default RegistryForm