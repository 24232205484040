import React from "react"

import RegistryForm from "../../components/RegistryForm"

import { Section, 
         Container, 
         Column,
         Subtitle } from "./styles"

const CorporateSection = ({ className, imgFluid }) => {
   return (
      <Section className={className}>
         <Container>
            <Column className="column1">
               <Subtitle 
                  subtitle="Registre-se para uma cotação comercial" 
               />
            </Column>
            <Column className="column2">
               <RegistryForm />
            </Column>
         </Container>
      </Section>
   )
}

export default CorporateSection