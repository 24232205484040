import styled from "styled-components";
import media from "../../styles/breakPoints"

import MainSection from "../../objects/MainSection"
import TitleSection from "../../objects/TitleSection"
import SubtitleSection from "../../objects/SubtitleSection"

const Section = styled(MainSection)`
   width: 100%;
   background-color: ${props => props.theme.color.secondyLight1};
   &.-variant {
      background-color: transparent;
   }
`;

const Container = styled.div`
   width: 100%;
   max-width: 1056px;
   padding: 1.6rem;
   margin: 0 auto;

   ${media.greaterThan("md")`
      display: grid;
      grid-template: "column1 column2";
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-column-gap: 2.4rem;
      padding: 2.4rem;
   `}

`

const Column = styled.div`

   ${media.greaterThan("md")`
      &.column1 { grid-area: column1; }
      &.column2 { grid-area: column2; }
  `}

`

const Title = styled(TitleSection)`

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.veryBig};
      line-height: 4.8rem;
      text-align: right;
   `}

`

const Subtitle = styled(SubtitleSection)`
   color: ${props => props.theme.color.thirdDark1};

   ${media.greaterThan("md")`
      text-align: right;
   `}

`

const DestakPhoto = styled.div`
  width: 100%;
  height: calc(width);
  margin-bottom: 1.6rem;
`

export { 
  Section, 
  Container, 
  Column,
  Title,
  Subtitle,
  DestakPhoto,
}