import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import Layout from "../../components/Layout";
import SEO from "../../components/seo"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import CorporateSection from "../../components/CorporateSection"
import RegistrySection from "../../components/RegistrySection"
import InviteSection from "../../components/InviteSection"

const QuemSomos = () => {

   const imgContent = useStaticQuery (
      graphql`
         query {
            imgCorporate: 
            file(relativePath: { eq: "venda-corporativa.jpg" }) {
               childImageSharp {
                  fluid(maxWidth:472) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }
      `
   )

   return (
      <Layout>
         <SEO title="Vendas Corporativas"/>
         <Header title=""/>
         <CorporateSection 
            className=""
            imgFluid={imgContent.imgCorporate.childImageSharp.fluid}
         />
         <RegistrySection className="-variant"/>
         <InviteSection />
         <Footer />
      </Layout>
   )
}

export default QuemSomos;