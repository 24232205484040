import React from "react"

import { Section, Title } from "./styles"

const InviteSection = ({ className }) => {
   return (
      <Section className={className}>
         <Title>
            Venha nos conhecer. Será um prazer receber você!
         </Title>
      </Section>
   )
}

export default InviteSection