import React from "react"
import styled from "styled-components";

const Text = styled.p`
  font-family: ${props => props.theme.font.secondy};
  font-size: ${props => props.theme.size.small};
  line-height: 2.4rem;
  font-weight: 400;
  color: ${props => props.theme.color.primaryLight2};
`

const TextSection = ({ children, className }) => {
   return (
      <Text className={className}>{children}</Text>
   )
}

export default TextSection