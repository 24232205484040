import React from "react"
import styled, { css } from "styled-components";

const titles = css`
   font-family: ${props => props.theme.font.primary};
   font-size: ${props => props.theme.size.big};
   font-weight: 600;
   line-height: 4rem;
   color: ${props => props.theme.color.primaryLight2};
   text-transform: uppercase;
`

const TitlePage = styled.h1`
  ${titles}
`

const Title = styled.h2`
  ${titles}
`

const TitleSection = ({ titlepage, titlesection, className }) => (
   titlepage ? 
      <TitlePage className={className}>{titlepage}</TitlePage> :
      <Title className={className}>{titlesection}</Title>
)

export default TitleSection