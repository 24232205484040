import styled from "styled-components";
import media from "../../styles/breakPoints"

import MainSection from "../../objects/MainSection"
import SubtitleSection from "../../objects/SubtitleSection"

const Section = styled(MainSection)`
   width: 100%;
   background-color: ${props => props.theme.color.secondyLight1};
   padding: 1.6rem 0;
   &.-variant {
      background-color: transparent;
   }

   ${media.greaterThan("sm")`
      padding: 2.4rem 0;
   `}

`;

const Container = styled.div`
   width: 100%;
   max-width: 1056px;
   padding: 1.6rem;
   margin: 0 auto;

   ${media.greaterThan("md")`
      display: grid;
      grid-template: "column1 column2";
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-column-gap: 2.4rem;
      padding: 2.4rem;
   `}

`

const Column = styled.div`

   ${media.greaterThan("md")`
      &.column1 { grid-area: column1; }
      &.column2 { grid-area: column2; }
  `}

`

const Subtitle = styled(SubtitleSection)`

   ${media.greaterThan("md")`
      text-align: right;
      width: 225px;
      margin-left: auto;
      line-height: 3rem;
   `}

`

export { 
  Section, 
  Container, 
  Column,
  Subtitle
}