import styled from "styled-components";
import media from "../../styles/breakPoints"

import TextSection from "../../objects/TextSection"

const WrapForm = styled.form`
   width: 100%;
   height: auto;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(5, 1fr) 2rem repeat(3, 1fr) ;
   grid-gap: 2.4rem;

   ${media.greaterThan("sm")`
      grid-template: "firstname lastname"
                     "company company"
                     "email email"
                     "phone phone"
                     "info info"
                     "business business"
                     "urlsite urlsite"
                     "btnsubmit btnsubmit";
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr) 2rem repeat(3, 1fr) ;
   `}

   ${media.greaterThan("md")`
      grid-template: "firstname"
                     "lastname"
                     "company"
                     "email"
                     "phone"
                     "info"
                     "business"
                     "urlsite"
                     "btnsubmit";
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 1fr) 2rem repeat(3, 1fr) ;
   `}

   ${media.greaterThan("xl")`
      grid-template: "firstname lastname"
                     "company company"
                     "email email"
                     "phone phone"
                     "info info"
                     "business business"
                     "urlsite urlsite"
                     "btnsubmit btnsubmit";
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr) 2rem repeat(3, 1fr) ;
   `}

`

const InputItem = styled.div`
   position: relative;
   width: 100%;

   ${media.greaterThan("sm")`
      &.firstname { grid-area: firstname; }
      &.lastname { grid-area: lastname; }
      &.company { grid-area: company; }
      &.email { grid-area: email; }
      &.phone { grid-area: phone; }
      &.business { grid-area: business; }
      &.urlsite { grid-area: urlsite; }
  `}

`


const FieldItem = styled.input`
   width: 100%;
   height: 4rem;
   color: ${props => props.theme.color.grayZero};
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.small};
   background-color: ${props => props.theme.color.primaryLight1};
   padding: 1rem 1.6rem;
   border: none;
   outline: none;
   resize: none;
`

// const TextArea = styled.textarea`
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    top: 0;
//    left: 0;
//    color: ${props => props.theme.color.secondyMedium};
//    font-family: ${props => props.theme.font.secondy};
//    font-size: ${props => props.theme.size.mediumSmall};
//    padding: 10px;
//    border: none;
//    outline: none;
//    resize: none;
// `

// const TextAreaItem = styled(InputItem)`
//    width: 100%;
//    /* max-width: 100%; */
//    height: 120px;
// `

const MsgErrorField = styled.p`
   width: 100%;
   position: absolute;
   bottom: -35px;
   left: 0;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.verySmall};
   color: ${props => props.theme.color.thirdDark2};
`

const InfoAdd = styled(TextSection)`
   margin: 0 0 -1rem;
   padding-left: 1.6rem;
   display: flex;
   align-self: flex-end;

   ${media.greaterThan("sm")`
      &.info { 
         grid-area: info;
      }
   `}
`

const ActionArea = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;

   ${media.greaterThan("sm")`
      &.btnsubmit { grid-area: btnsubmit; }
  `}

`

const Button = styled.button`
   height: 40px;
   border: none;
   border-radius: 3px;
   outline: none;
   padding: 0 30px;
   transition: all 0.3s linear;
   text-decoration: none;
   font-family: ${props => props.theme.font.secondy};
   text-transform: uppercase;
   font-size: ${props => props.theme.size.small};
   font-weight: 600;
   color: ${props => props.theme.color.grayDark};
   letter-spacing: 0.05rem;
   background-color: ${props => props.theme.color.fifthLight1};
   cursor: pointer;
   transition: transform 0.3s linear;
   &:hover {
      transform: scale(1.05);
      transition: transform 0.3s linear;
   }
`

export {
   WrapForm,
   InputItem,
   FieldItem,
   // TextArea,
   // TextAreaItem,
   MsgErrorField,
   InfoAdd,
   ActionArea,
   Button
}