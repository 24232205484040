import styled from "styled-components";
import media from "../../styles/breakPoints"

import MainSection from "../../objects/MainSection"

const Section = styled(MainSection)`
   width: 100%;
   background-color: ${props => props.theme.color.fifthLight1};
`

const Title = styled.h2`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: ${props => props.theme.font.primary};
   font-size: ${props => props.theme.size.medium};
   line-height: 3rem;
   font-weight: 600;
   color: ${props => props.theme.color.primaryLight2};
   padding: 1.6rem 3.2rem;
   text-align: center;
   margin: 0;

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.big};
      line-height: 4rem;
      padding: 3.2rem;
   `}

`
export {
   Section,
   Title
}
